import maryem from "../../../assets/img/2024-2025/mtts/maryem.jpeg";
import ibrahim from "../../../assets/img/2024-2025/mtts/ibrahim ghorbel.jpg";
import skander from "../../../assets/img/2024-2025/mtts/skander.jpeg";
import hamda from "../../../assets/img/2024-2025/mtts/hamda.jpeg";
import nour from "../../../assets/img/2024-2025/mtts/nour.jpeg";
import dorsaf from "../../../assets/img/2024-2025/mtts/dorsaf.jpeg";


const officers = [
    {
        "name": "Maryem Segni",
        "position": "Chairwoman",
        "image": maryem,
        "chapter": "mtts"
    },
    {
        "name": "Ibrahim Aziz Ghorbel",
        "position": "Vice Chairwoman",
        "image": ibrahim,
        "chapter": "mtts"
    },
    {
        "name": "Skander Chaouachi",
        "position": "Secretary",
        "image": skander,
        "chapter": "mtts"
    },
    {
        "name": "Hamda Arfa",
        "position": "Treasurer",
        "image": hamda,
        "chapter": "mtts"
    },
    {
        "name": "Nour Slimen",
        "position": "Webmaster",
        "image": nour,
        "chapter": "mtts"
    },
    {
        "name": "Dorsaf Khlaifia",
        "position": "Human Resources Manager",
        "image": dorsaf,
        "chapter": "mtts"
    },
]

export default officers