import React from "react";
import styled from "styled-components";
// Components
import FullButton from "../Buttons/FullButton";
// Assets
import QuotesIcon from "../../assets/svg/Quotes";
import Dots from "../../assets/svg/Dots";
import header from "../../assets/img/ieeeday photo.jpg";
import fsthackathon from "../../assets/img/2023-2024/projects/cs/FST-HACKATHON.jpg";
import agwie from "../../assets/img/2023-2024/projects/wie/AG.jpg";
import agias from "../../assets/img/2023-2024/projects/ias/agias.jpg";
import aggrss from "../../assets/img/2023-2024/projects/grss/DSC_0049.jpg"
import agpes from "../../assets/img/2023-2024/projects/pes/AG-1-PES.jpg"
import agras from "../../assets/img/2023-2024/projects/ras/GA.png"
import innomtts from "../../assets/img/inno.jpg"

export default function Header({chapter}) {
  const navigate = (id) => {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }
  return (
    <Wrapper id="home" className="container flexSpaceCenter" style={{backgroundImage: header}}>
      <LeftSide className="flexCenter">
        <div>
          <h1 className="extraBold font60">We are IEEE FST {chapter === "sb" ? "Student Branch" :
              chapter === "cs" ? "SB CS Chapter" :
                  chapter === "wie" ? "WIE Affinity Group" :
                      chapter === "ias" ? "IAS Chapter" :
                          chapter === "grss" ? "GRSS Chapter" :
                              chapter === "pes" ? "PES Chapter" :
                                  chapter === "ras" ? "RAS Chapter" :
                                      chapter === "mtts" ? "MTTS Chapter" :
                  ""}
          </h1>
          <HeaderP className="font13 semiBold">
            {chapter === "sb" ? "IEEE FST STUDENT BRANCH IS A COMMUNITY OF STUDENTS WHO ARE PASSIONATE ABOUT TECHNOLOGY AND WORKING TOGETHER TO MAKE THE WORLD A BETTER PLACE." :
                chapter === "cs" ? "IEEE Computer Society – FST Student Branch (CS), approved on March 2, 2020, is a chapter of the IEEE FST SB that innovates and deals with topics in the IT field. From organizing events, hosting workshops and conferences." :
                    chapter === "wie" ? "IEEE FST Women In Engineering Affinity Group (WIE) is the largest international professional organization, was officially approved in April 2020, with its main goal to promote the presence and impact of women in science and engineering." :
                    chapter === "ias" ? "IEEE FST Industry Applications Society Chapter (IAS) is a chapter of the IEEE FST SB that innovates and deals with topics in the IT field. From organizing events, hosting workshops and conferences." :
                        chapter === "grss" ? "IEEE FST Geoscience and Remote Sensing Society (GRSS) is one the largest academic and professional Societies, founded in 2020, it fosters engagement of its members for the benefit of society through science, engineering, applications, and education as related to the development of the field of geoscience and remote sensing." :
                            chapter === "pes" ? "IEEE FST Power and Energy Society (PES) was established in 2020 by a couple of IEEE FST SB members with a passion for electric power and energy, with a mission to be the leading provider of scientific and engineering in the power field." :
                            chapter === "ras" ? "IEEE FST Robotics and Automation Society (RAS) is one of IEEE’s 39 technical chapters. The IEEE RAS Chapter – FST Student Branch, created in September 2019, strives to advance innovation, education and research fundamental and applied in robotics and automation through training, events, seminars. IEEE RAS FST SBC is now FST’s biggest attraction for students passionate about the world of robotics and automation." :
                                chapter === "mtts" ? "The MTT-S IEEE FST SBC is a regional branch of the IEEE Microwave Theory and Techniques Society, focusing on advancing research and applications in microwave and RF technologies" :
                                ""}

          </HeaderP>
          <BtnWrapper>
            <FullButton title="Show More" action={() => navigate('about')} />
          </BtnWrapper>
        </div>
      </LeftSide>
      <RightSide>
        <ImageWrapper>
          <Img className="radius8" src={chapter === "sb" ? header : chapter === "cs" ? fsthackathon : chapter === "wie" ? agwie : chapter === "ias" ? agias : chapter === "grss" ? aggrss : chapter === "pes" ? agpes : chapter === "ras" ? agras : chapter === "mtts" ? innomtts : ""} alt="office" style={{backgroundSize: "cover", backgroundPosition: "center", width: 1280, height: 607, overflow: "hidden"}} />
          <QuoteWrapper className="flexCenter darkBg radius8">
            <QuotesWrapper>
              <QuotesIcon />
            </QuotesWrapper>
            <div>
              <p className="font15 whiteColor">
                <em>Friends, such as we desire, are dreams and fables. Friendship demands the ability to do without it.</em>
              </p>
              <p className="font13 orangeColor textRight" style={{marginTop: '10px'}}>Ralph Waldo Emerson</p>
            </div>
          </QuoteWrapper>
          <DotsWrapper>
            <Dots />
          </DotsWrapper>
        </ImageWrapper>
        <GreyDiv className="lightBg"></GreyDiv>
      </RightSide>
    </Wrapper>
  );
}


const Wrapper = styled.section`
  padding-top: 80px;
  width: 100%;
  min-height: 840px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
`;
const LeftSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 2;
    margin: 50px 0;
    text-align: center;
  }
  @media (max-width: 560px) {
    margin: 80px 0 50px 0;
  }
`;
const RightSide = styled.div`
  width: 50%;
  height: 100%;
  @media (max-width: 960px) {
    width: 100%;
    order: 1;
    margin-top: 30px;
  }
`;
const HeaderP = styled.div`
  max-width: 470px;
  padding: 15px 0 50px 0;
  line-height: 1.5rem;
  @media (max-width: 960px) {
    padding: 15px 0 50px 0;
    text-align: center;
    max-width: 100%;
  }
`;
const BtnWrapper = styled.div`
  max-width: 190px;
  @media (max-width: 960px) {
    margin: 0 auto;
  }
`;
const GreyDiv = styled.div`
  width: 30%;
  height: 700px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
  @media (max-width: 960px) {
    display: none;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
const QuoteWrapper = styled.div`
  position: absolute;
  left: -150px;
  bottom: 50px;
  max-width: 330px;
  padding: 30px;
  z-index: 99;
  @media (max-width: 960px) {
    left: 20px;
  }
  @media (max-width: 560px) {
    bottom: -50px;
  }
`;
const QuotesWrapper = styled.div`
  position: absolute;
  left: -20px;
  top: -10px;
`;
const DotsWrapper = styled.div`
  position: absolute;
  right: -100px;
  bottom: 100px;
  z-index: 2;
  @media (max-width: 960px) {
    right: 100px;
  }
  @media (max-width: 560px) {
    display: none;
  }
`;


