import React, {useEffect} from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import ServiceBox from "../Elements/ServiceBox";
import FullButton from "../Buttons/FullButton";

import CsChapter from "../../assets/img/chapters/cs-chapter.jpg";
import RASChapter from "../../assets/img/chapters/ras-chapter.png";
import MTTSChapter from "../../assets/img/chapters/blue.png";
import WIE from "../../assets/img/chapters/wie.png";
import IASChapter from "../../assets/img/chapters/ias.png";
import PESChapter from "../../assets/img/chapters/pes-chapter.png";
import GRSSChapter from "../../assets/img/chapters/grss-chapter.jpg";
import {useNavigate} from "react-router-dom";
import {getAccessToken} from "../../utils";
import axios from "axios";


export default function Services({chapter}) {
    const navigate = useNavigate();
    const navigateIntoView = (id) => {
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
    }
    return (
        <Wrapper>
            <div className="lightBg" style={{padding: "50px 0"}} id="about">
                <div className="container">
                    <ClientSlider/>
                </div>
            </div>
            <div className="whiteBg" style={{padding: "60px 0"}}>
                <div className="container" id="about">
                    <HeaderInfo>
                        <h1 className="font40 extraBold textCenter">About Us</h1>
                        <p className="font13 textCenter">
                            {chapter === "sb" ?
                                "IEEE FST STUDENT BRANCH IS A COMMUNITY OF STUDENTS WHO ARE PASSIONATE ABOUT TECHNOLOGY AND WORKING TOGETHER TO MAKE THE WORLD A BETTER PLACE." :
                                chapter === "cs" ?
                                    "We help students to improve their IT skills, learn new technologies, new programming languages and other aspects of the tech field. Not only that, but we also work on developing our members’ soft skills." :
                                    chapter === "wie" ?
                                        "IEEE FST WIE is a community of students who are passionate about technology and working together to make the world a better place. The mission of IEEE FST WIE Affinity Group is to facilitate the global recruitment and retention of women in technical disciplines. IEEE WIE envisions a vibrant community of IEEE women and men collectively using their diverse talents to innovate for the benefit of humanity." +
                                        "The WIE Affinity Group - FST Student Branch was officially approved in April 2020. Despite the current situation of the Covid-19 pandemic, we have successfully launched the WIE FST on social networks (Facebook and Instagram)." :
                                        chapter === "ias" ?
                                            "The IEEE IAS FST Student Branch Chapter aims to create a linking bridge between the academic and the industrial for its community while sharing specific industry-related solutions." +

                                            "We are striving to fulfill our mission through believing in our purpose and most of all believing in ourselves and what our diversity can lead us to."
                                            :
                                            chapter === "grss" ? "IEEE Geoscience and Remote Sensing FST Society supports and promotes advances in science and technology in the fields of science earth, remote sensing as well as related sectors. This support takes place, among other things, through conferences and training support."
                                                :
                                                chapter === "pes" ? "IEEE FST PES Chapter strives to be the leading provider of scientific and engineering information on electric power and energy for the betterment of society, and preferred professional development source of its members."
                                                    :
                                                    chapter === "ras" ? "Consists on enlarging automation and robotics knowledge through organizing weekly workshops, participating in and organizing national competitions and preparing the members for the professional life through integrating them in a network of young professionals and engineers."
                                                        :
                                                        chapter === "mtts" ? "The chapter promotes knowledge sharing through conferences, workshops, and technical meetings, fostering the development of new microwave systems, circuits, and components. Key areas of interest include microwave propagation, electromagnetic wave behavior, and signal integrity, with applications spanning telecommunications, radar, and medical imaging."
                                                            :
                                                        ""
                            }
                            <br/>
                            {chapter === "sb" ?
                                "IEEE FST SB’s mission is to provide the community of its future engineers with the necessary\n" +
                                "                            knowledge, give them the right tools and network to build up their skills and get into the\n" +
                                "                            professional world as well." :
                                chapter === "cs" ?
                                    "The IEEE CS FST Chapter, founded in March 2020, has started with a small group of students passionate about the IT field with a common goal to enlarge their knowledge and share their skills with others." :
                                    chapter === "ias" ?
                                        "Our mission is to contribute to enabling the advancement of theory and practice in the design, development, manufacturing and application of safe, sustainable, reliable, smart electrical systems, equipment and services through projects implementation, events and programs. Officially formed in early 2021, the IEEE IAS FST SBC brings together a group of students and young professionals from all different fields of engineering together in order to apply theoretical knowledge acquired into practical. Since its formation, the IAS FST Chapter is constantly improving and remaining as active as possible."
                                        :
                                        chapter === "grss" ? "IEEE Geoscience and Remote Sensing FST Society (GRSS) was established in March 2020, by a group of IEEEians passionate about the geology word and eager to understand the interaction with earth’s ecosystems."
                                            :
                                            chapter === "pes" ? "IEEE FST PES Chapter was established in february 2020 by a couple of dedicated IEEE members. Since then, the work has started and a lot of activities took place reuniting all our members."
                                                :
                                                chapter === "ras" ? "IEEE FST RAS Chapter was officially approved in 2020. It has started with just a few students passionate about creating things and learning about the world of robotics. With this motivation and vision, we held various events, training and competitions and we are proud to have a strong community of members always sharing their knowledge and developing their skills."
                                                    :
                                                    chapter === "mtts" ? "Additionally, the chapter supports educational outreach and professional development for students and emerging professionals, while facilitating collaboration between academia and industry to drive innovation in microwave technology." :
                                                    ""
                            }
                        </p>
                    </HeaderInfo>
                    {chapter === "sb" ?
                        <>
                            <ServiceBoxRow className="flex">
                                <ServiceBoxWrapper>
                                    <ServiceBox
                                        icon="users"
                                        title="409 Members"
                                    />
                                </ServiceBoxWrapper>
                                <ServiceBoxWrapper>
                                    <ServiceBox
                                        icon="user-tie"
                                        title="32 Officers"
                                    />
                                </ServiceBoxWrapper>
                                <ServiceBoxWrapper>
                                    <ServiceBox
                                        icon="calendar-days"
                                        title="69 Events"
                                    />
                                </ServiceBoxWrapper>
                                <ServiceBoxWrapper>
                                    <ServiceBox
                                        icon="award"
                                        title="9 Awards"
                                    />
                                </ServiceBoxWrapper>
                            </ServiceBoxRow>
                            <Wrapper id={"chapters"}>
                                <HeaderInfo>
                                    <h1 className="font40 extraBold textCenter">Our Chapters & Affinity Groups</h1>
                                </HeaderInfo>
                                <ServiceBoxRow className="flex">
                                    <ServiceBoxWrapper>
                                        <ServiceBox image={RASChapter} title="RAS Chapter"
                                                    action={() => navigate("/ras-chapter")}
                                                    subtitle=" IEEE FST Robotics and Automation Society (RAS) is one of IEEE’s 6 technical chapters. The IEEE RAS Chapter – FST Student Branch, created in September 2019 strives to advance innovation, education and research fundamental and applied in robotics and automation"/>
                                    </ServiceBoxWrapper>
                                    <ServiceBoxWrapper>
                                        <ServiceBox
                                            image={CsChapter}
                                            action={() => navigate("/cs-chapter")}
                                            title="CS Chapter"
                                            subtitle={"IEEE Computer Society – FST Student Branch (CS), approved on March 2, 2020, is a chapter of the IEEE FST SB that innovates and deals with topics in the IT field. From organizing events, hosting workshops and conferences."}
                                        />
                                    </ServiceBoxWrapper>
                                    <ServiceBoxWrapper>
                                        <ServiceBox
                                            image={WIE}
                                            title="Wie Affinity Group"
                                            action={() => navigate("/wie-affinity-group")}
                                            subtitle="IEEE FST Women In Engineering Affinity Group (WIE) is the largest international professional organization, was officially approved in April 2020, with its main goal to promote the presence and impact of women in science and engineering"
                                        />
                                    </ServiceBoxWrapper>

                                    <ServiceBoxWrapper>
                                        <ServiceBox image={GRSSChapter} title="GRSS Chapter"
                                                    action={() => navigate("/grss-chapter")}
                                                    subtitle="IEEE FST Geoscience and Remote Sensing Society (GRSS) is one the largest academic and professional Societies, founded in 2020, it fosters engagement of its members for the benefit of society through science, engineering, applications, and education as related to the development of the field of geoscience and remote sensing."/>
                                    </ServiceBoxWrapper>
                                    <ServiceBoxWrapper>
                                        <ServiceBox image={PESChapter} title="PES Chapter"
                                                    action={() => navigate("/pes-chapter")}
                                                    subtitle="IEEE FST Power and Energy Society (PES) was established in 2020 by a couple of IEEE FST SB members with a passion for electric power and energy, with a mission to be the leading provider of scientific and engineering in the power field"/>
                                    </ServiceBoxWrapper>
                                    <ServiceBoxWrapper>
                                        <ServiceBox
                                            image={IASChapter}
                                            title="IAS Chapter"
                                            action={() => navigate("/ias-chapter")}
                                            subtitle="IEEE Industry Applications Society – FST Student Branch (IAS) is a chapter of the IEEE FST SB that deals with topics in the field of electrical engineering. From organizing events, hosting workshops and conferences."
                                        />
                                    </ServiceBoxWrapper>
                                    <ServiceBoxWrapper>
                                        <ServiceBox image={MTTSChapter} title="MTT-S Chapter"
                                                    action={() => navigate("/mtts-chapter")}
                                                    subtitle=" IEEE FST Microwave Theory and Technology Society (MTT-S) is one of IEEE’s 6 technical chapters. The IEEE MTT-S Chapter – FST Student Branch, created in February 2024 strives to advance innovation, education and research fundamental and applied in microwave and technology"/>
                                    </ServiceBoxWrapper>
                                </ServiceBoxRow>
                            </Wrapper>
                        </> : ""
                    }
                </div>
                {chapter === "sb" ?
                    <Wrapper id={"chapters"}>
                        <div className="lightBg" id={"chapters"}>
                            <div className="container">
                                <Advertising className="flexSpaceCenter">
                                    <AddLeft>
                                        <h4 className="font15 semiBold">A few words about our student branch</h4>
                                        <h2 className="font40 extraBold">A Study of Creativity</h2>
                                        <p className="font12">
                                            Every year, IEEE FST SB organizes a wide range of events, workshops, and
                                            conferences. We are also proud to have a large number of members who are
                                            passionate about new technologies and creating innovations for the benefit
                                            of
                                            our
                                            community and of humanity in general.
                                        </p>
                                        <ButtonsRow className="flexNullCenter" style={{margin: "30px 0"}}>
                                            <div style={{width: "190px"}}>
                                                <FullButton title="Join Us"/>
                                            </div>
                                            <div style={{width: "190px", marginLeft: "15px"}}>
                                                <FullButton title="Contact Us" border
                                                            action={() => navigateIntoView('contact')}/>
                                            </div>
                                        </ButtonsRow>
                                    </AddLeft>
                                    <AddRight>
                                        <div className="flexCenter">
                                            <iframe className="elementor-video-iframe" allowFullScreen=""
                                                    title="youtube Video Player" width="920" height="480"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    src="https://www.youtube.com/embed/LJlTlKObVAU?feature=oembed&amp;start&amp;end&amp;wmode=opaque&amp;loop=0&amp;controls=1&amp;mute=0&amp;rel=0&amp;modestbranding=0"></iframe>
                                        </div>
                                    </AddRight>
                                </Advertising>
                            </div>
                        </div>
                    </Wrapper> : ""
                }
            </div>
        </Wrapper>
    );
}

const Wrapper = styled.section`
    width: 100%;
`;
const ServiceBoxRow = styled.div`
    @media (max-width: 860px) {
        flex-direction: column;
    }
`;
const ServiceBoxWrapper = styled.div`
    width: 20%;
    margin-right: 5%;
    padding: 80px 0;
    @media (max-width: 860px) {
        width: 100%;
        text-align: center;
        padding: 40px 0;
    }
`;
const HeaderInfo = styled.div`
    @media (max-width: 860px) {
        text-align: center;
    }
`;
const Advertising = styled.div`
    margin: 80px 0;
    padding: 100px 0;
    position: relative;
    @media (max-width: 1160px) {
        padding: 100px 0 40px 0;
    }
    @media (max-width: 860px) {
        flex-direction: column;
        padding: 0 0 30px 0;
        margin: 80px 0 0px 0;
    }
`;
const ButtonsRow = styled.div`
    @media (max-width: 860px) {
        justify-content: space-between;
    }
`;
const AddLeft = styled.div`
    width: 50%;

    p {
        max-width: 475px;
    }

    @media (max-width: 860px) {
        width: 80%;
        order: 2;
        text-align: center;
        h2 {
            line-height: 3rem;
            margin: 15px 0;
        }

        p {
            margin: 0 auto;
        }
    }
`;
const AddRight = styled.div`
    width: 50%;
    position: absolute;
    top: -70px;
    right: 0;
    @media (max-width: 860px) {
        width: 80%;
        position: relative;
        order: 1;
        top: -40px;
    }
`;