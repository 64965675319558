const activities = [
    // {
    //     title: "FST Robots 3.0",
    //     description: "25 December | 8 PM | Online To start the holidays, we tackled our Level 1 Printed Circuit Board ( PCB ) design workshop with Farés Aissa’s, and Mahmoud Abidi’s guidance.",
    //     image: fstrobots3,
    //     link: "https://www.google.com",
    //     chapter: "mtts"
    // },
]

export default activities