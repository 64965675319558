import sbactivities from "./sb/activities"
import csactivities from "./cs/activities"
import wieactivities from "./wie/activities"
import iasactivities from "./ias/activities"
import grssactivities from "./grss/activities"
import pesactivities from "./pes/activities"
import rasactivities from "./ras/activities"
import mttsactivities from "./mtts/activities"

const activities = [
    ...sbactivities,
    ...csactivities,
    ...wieactivities,
    ...iasactivities,
    ...grssactivities,
    ...pesactivities,
    ...rasactivities,
    ...mttsactivities,
]

export default activities;