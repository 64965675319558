import React from "react";
import styled from "styled-components";
// Components
import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";
// Assets
import header from "../../assets/img/og1.0.jpg";
import hackathon from "../../assets/img/2023-2024/projects/cs/hackathon.jpg";
import wie11 from "../../assets/img/2023-2024/projects/wie/wie11.jpg";
import ias from "../../assets/img/2023-2024/projects/ias/IMG_9905.jpg";
import grss from "../../assets/img/2023-2024/projects/grss/DSC_0049.jpg"
import agpes from "../../assets/img/2023-2024/projects/pes/AG-1-PES.jpg"
import arduino from "../../assets/img/2023-2024/projects/ras/Arduino-1.jpg"
import agmtts from "../../assets/img/2024-2025/agmtts.jpg"
import activities from "../../data/2023-2024/activities";
import {useNavigate} from "react-router-dom";

export default function Projects({chapter}) {
  const navigate = useNavigate()
  const navigateTo = (id) => {
    document.getElementById(id).scrollIntoView({behavior: "smooth"});
  }
  return (
    <Wrapper id="projects">
      <div className="whiteBg">
        <div className="container">
          <HeaderInfo>
            <h1 className="font40 extraBold textCenter">Our Awesome Projects</h1>
            <p className="font13 textCenter">
              Project is an opportunity to take action.
            </p>
          </HeaderInfo>
          <div className="row textCenter">
            {activities.filter(activity => activity.chapter === chapter).slice(0, 3).map((activity) => (
                <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                    <ProjectBox
                    img={activity.image}
                    title={activity.title}
                    text={activity.description}
                    link={activity.link}
                    />
                </div>
                ))
            }
          </div>
          <div className="row flexCenter">
            <div style={{ margin: "50px 0", width: "200px" }}>
              <FullButton title="Load More" action={() => navigate('/projects')} />
            </div>
          </div>
        </div>
      </div>
      <div className="lightBg">
        <div className="container">
          <Advertising className="flexSpaceCenter">
            <AddLeft>
              <AddLeftInner>
                <ImageWrapper className="flexCenter">
                  <Img className="radius8" src={chapter === "sb" ? header : chapter === "cs" ? hackathon : chapter === "wie" ? wie11 : chapter === "ias" ? ias : chapter === "grss" ? grss : chapter === "pes" ? agpes : chapter === "ras" ? arduino : chapter === "mtts" ? agmtts : ""} alt="office" style={{backgroundSize: "cover", backgroundPosition: "center", width: 1280, height: 607, overflow: "hidden"}} />
                </ImageWrapper>
              </AddLeftInner>
            </AddLeft>
            <AddRight>
              <h4 className="font15 semiBold">A few words about us</h4>
              <h2 className="font40 extraBold">A Study of Creativity</h2>
              <p className="font12">
                Every project is a new opportunity to do something different, and we are always looking for new opportunities.
                Our events are designed to inspire, educate and entertain. We are a team of creative people who are passionate about what they do.
              </p>
              <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                <div style={{ width: "190px" }}>
                  <FullButton title="Join Us" />
                </div>
                <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" border action={() => navigateTo('contact')} />
                </div>
              </ButtonsRow>
            </AddRight>
          </Advertising>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 40%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 9;
  @media (max-width: 960px) {
    width: 100%;
    justify-content: center;
  }
`;
const Img = styled.img`
  @media (max-width: 560px) {
    width: 80%;
    height: auto;
  }
`;
